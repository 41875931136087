<template>
  <div class="ticket-details">
    <div class="ticket-details__nav">
      <div class="ticket-select">
        <div class="ticket-select__item" id="select" @click="popup = true">
          <img :src="selected.img" height="16" width="16" alt="" />

          <p>
            {{ selected.name }}
          </p>

          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L3 3L5 1" stroke="black" />
          </svg>
        </div>

        <transition name="fade" mode="out-in">
          <div
            class="ticket-popup"
            v-if="popup"
            v-click-outside="onClickOutside"
          >
            <div class="ticket-popup__selected" @click="popup = false">
              <img :src="selected.img" height="16" width="16" alt="" />

              <p>
                {{ selected.name }}
              </p>

              <svg
                width="6"
                height="4"
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L3 3L5 1" stroke="black" />
              </svg>
            </div>

            <div
              class="ticket-popup__list"
              v-for="item in filteredOptions"
              :key="item.name"
              @click="selectOption(item)"
            >
              <div class="ticket-popup__item">
                <img :src="item.img" height="16" width="16" alt="" />

                <p>
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="ticket-details__info">
      <transition name="slide-fade" mode="out-in">
        <ticket-user-info v-if="selected.value === 'user'" />

        <ticket-history v-if="selected.value === 'history'" />

        <ticket-comments v-if="selected.value === 'comments'" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TicketUserInfo from "@/components/ticket/TicketUserInfo";
import TicketHistory from "@/components/ticket/TicketHistory";
import TicketComments from "@/components/ticket/TicketComments";

export default {
  name: "TicketDetails",
  components: {
    TicketUserInfo,
    TicketHistory,
    TicketComments
  },

  props: {
    id: {
      type: Number,
      required: true
    },
    userID: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      popup: false,

      selected: {
        name: "Комментарии",
        value: "comments",
        img: require("../../assets/icons/comments.svg")
      },

      options: [
        {
          name: "Комментарии",
          value: "comments",
          img: require("../../assets/icons/comments.svg")
        },
        {
          name: "О пользователе",
          value: "user",
          img: require("../../assets/icons/user.svg")
        },
        {
          name: "История тикета",
          value: "history",
          img: require("../../assets/icons/history.svg")
        }
      ]
    };
  },

  watch: {
    "$route.params.id": function() {
      this.selected = this.options[0];
      this.loadTicketComments(this.id);
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.ticket.userInfo,
      loadingUserInfo: state => state.ticket.loadingUserInfo
    }),

    filteredOptions() {
      return this.options.filter(item => {
        if (item.value !== this.selected.value) {
          return item;
        }
      });
    }
  },

  methods: {
    ...mapActions({
      loadTicketLogs: "ticket/loadTicketLogs",
      loadTicketComments: "ticket/loadTicketComments",
      loadUserInfo: "ticket/loadUserInfo"
    }),

    selectOption(item) {
      if (item.value === "comments") {
        this.loadTicketComments(this.id);
      } else if (item.value === "history") {
        this.loadTicketLogs(this.id);
      } else {
        this.loadUserInfo(this.userID);
      }
      this.selected = item;
      this.popup = false;
    },

    onClickOutside() {
      this.popup = false;
    }
  },

  created() {
    this.loadTicketComments(this.id);
  }
};
</script>

<style lang="scss" scoped>
.ticket-details {
  flex: 335px 0 0;
  background: #f5f6fa;

  &__nav {
    .ticket-select {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      border-bottom: 1px solid #eeeeee;
      position: relative;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 6px;

        p {
          margin: 0 4px 0 8px;
          font-size: 14px;
          color: $label-second;
        }

        svg {
          path {
            stroke: $label-second;
          }
        }
      }

      .ticket-popup {
        position: absolute;
        top: 6px;
        background: #ffffff;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        z-index: 999;

        &__selected {
          border-bottom: 1px solid #eeeeee;
        }

        &__selected,
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
          width: 300px;
          cursor: pointer;

          p {
            font-size: 14px;
            color: $label-second;
          }
        }

        &__selected {
          height: 64px;

          p {
            margin: 0 4px 0 8px;
          }

          svg {
            path {
              stroke: $label-second;
            }
          }
        }

        &__item {
          p {
            margin-left: 8px;
          }

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  &__info {
    padding: 40px 32px 24px;
    height: calc(100vh - 70px);
  }
}
</style>
