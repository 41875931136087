<template>
  <div class="editor" v-if="status !== 'done'">
    <div class="editor__wrapper">
      <div class="editor__edit-message" v-if="editMessageID">
        <p>Редактирование сообщения</p>

        <button @click="resetMessageEditRequest">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.19998 0.730891L6.99998 5.53089L11.8 0.745437C11.8945 0.649276 12.0079 0.573771 12.1331 0.523698C12.2583 0.473624 12.3925 0.450071 12.5273 0.454528C12.7917 0.471639 13.0408 0.584406 13.2282 0.77177C13.4156 0.959135 13.5283 1.20829 13.5454 1.47271C13.5467 1.60272 13.5216 1.73164 13.4716 1.85166C13.4216 1.97167 13.3478 2.08028 13.2545 2.17089L8.43998 6.99998L13.2545 11.8291C13.4436 12.0123 13.5483 12.2654 13.5454 12.5273C13.5283 12.7917 13.4156 13.0408 13.2282 13.2282C13.0408 13.4156 12.7917 13.5283 12.5273 13.5454C12.3925 13.5499 12.2583 13.5263 12.1331 13.4763C12.0079 13.4262 11.8945 13.3507 11.8 13.2545L6.99998 8.46907L2.21452 13.2545C2.12001 13.3507 2.00658 13.4262 1.8814 13.4763C1.75621 13.5263 1.622 13.5499 1.48725 13.5454C1.2179 13.5314 0.963286 13.4181 0.772566 13.2274C0.581847 13.0367 0.468542 12.7821 0.454521 12.5127C0.453205 12.3827 0.478312 12.2538 0.528318 12.1338C0.578325 12.0137 0.652189 11.9051 0.745431 11.8145L5.55998 6.99998L0.730885 2.17089C0.640269 2.07906 0.569093 1.9699 0.521609 1.84994C0.474126 1.72998 0.451309 1.60168 0.454521 1.47271C0.471633 1.20829 0.584399 0.959135 0.771764 0.77177C0.959129 0.584406 1.20828 0.471639 1.4727 0.454528C1.60641 0.448177 1.73999 0.469439 1.86511 0.516987C1.99024 0.564534 2.10423 0.637349 2.19998 0.730891Z"
              fill="#212121"
            />
          </svg>
        </button>
      </div>

      <quill-editor
        class="editor"
        v-model="content"
        :options="editorOptions"
        @keydown.enter.native="handleKeypress($event)"
      >
      </quill-editor>

      <button
        class="editor__btn"
        :class="sending || !content ? 'editor__btn--disabled' : ''"
        @click="submitMessage"
        :disabled="sending || !content"
      ></button>
    </div>
    <div class="editor__files">
      <div class="input" v-show="files.length">
        <label>
          Вложения:
          <input
            type="file"
            id="files"
            ref="files"
            multiple
            v-on:change="handleFilesUpload()"
          />
        </label>
      </div>

      <div class="names" v-show="files.length">
        <div v-for="(file, key) in files" :key="key" class="file-listing">
          {{ file.name }}
          <span class="remove-file" v-on:click="removeFile(key)">
            Удалить
          </span>
        </div>
      </div>

      <div class="btns">
        <button v-on:click="addFiles">Прикрепить файл</button>
      </div>
    </div>

    <div class="editor__btns">
      <div
        :class="[
          'editor__presets-btn',
          presetsState ? 'editor__presets-btn--rotate' : ''
        ]"
      >
        <button @click="presetsState = !presetsState">
          Готовые ответы
        </button>

        <svg
          width="6"
          height="4"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L3 3L5 1" stroke="black" />
        </svg>
      </div>

      <button class="editor__presets-edit" @click="showModal">
        Изменить
      </button>
    </div>

    <div
      :class="['editor__presets', presetsState ? 'editor__presets--open' : '']"
    >
      <div
        class="editor__preset"
        v-for="item in presets"
        :key="item.id"
        @click="selectPreset(item.id, 'select')"
      >
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { editorOptions } from "@/components/mixins/editorOptions.mixin";
import { eventBus } from "@/main";

export default {
  name: "TicketEditor",
  mixins: [editorOptions],
  props: {
    status: {
      type: String,
      required: true
    },
    showModal: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      sending: false,
      content: "",
      files: [],
      presetsState: false,
      editMessageID: ""
    };
  },

  watch: {
    "$route.params.id": function() {
      this.content = "";
      this.files = [];
    }
  },

  computed: {
    ...mapState({
      ticket: state => state.ticket.ticket,
      presets: state => state.message.presets
    }),

    ...mapGetters({
      getPresetById: "message/getPresetById"
    })
  },

  methods: {
    ...mapActions({
      sendMessage: "message/sendMessage",
      sendFiles: "message/sendFiles",
      editMessage: "message/editMessage"
    }),

    resetMessageEditRequest() {
      this.content = "";
      this.resetMessageEdit();
    },

    handleKeypress($event) {
      if (
        !$event.shiftKey &&
        $event.key === "Enter" &&
        this.content &&
        !this.sending
      ) {
        this.submitMessage();
      }
    },

    addFiles() {
      this.$refs.files.click();
    },

    removeFile(key) {
      this.files.splice(key, 1);
    },

    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (let i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },

    selectPreset(id) {
      this.content = this.getPresetById(id).text;
    },

    resetMessageEdit() {
      this.editMessageID = "";
    },

    submitMessage() {
      if (this.editMessageID) {
        this.sending = true;

        this.editMessage({
          id: this.ticket.id,
          messageID: this.editMessageID,
          content: this.content
        })
          .then(() => {
            this.content = "";
            this.sending = false;
            this.editMessageID = "";
          })
          .catch(() => {
            this.sending = false;
          });
      } else {
        let formData = new FormData();
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          formData.append("attachments[" + i + "]", file);
        }

        this.sending = true;

        if (this.files.length) {
          this.sendFiles(formData)
            .then(response => {
              this.sendMessage({
                id: this.ticket.id,
                content: this.content,
                attachments: response.data.attachments
              })
                .then(() => {
                  this.content = "";
                  this.files = [];
                  this.sending = false;
                })
                .catch(() => {
                  this.sending = false;
                });
            })
            .catch(() => {
              this.sending = false;
            });
        } else {
          this.sendMessage({
            id: this.ticket.id,
            content: this.content
          })
            .then(() => {
              this.content = "";
              this.files = [];
              this.sending = false;
            })
            .catch(() => {
              this.sending = false;
            });
        }
      }
    }
  },

  mounted() {
    eventBus.$on("editMessage", data => {
      this.editMessageID = data.id;
      this.content = data.text;
    });
  }
};
</script>

<style lang="scss">
.editor {
  width: 100%;
  padding: 25px 40px 15px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin-top: auto;
  word-break: break-word;

  @media (max-width: 768px) {
    padding: 25px 20px 15px;
  }

  &__wrapper {
    position: relative;
  }

  &__btn {
    position: absolute;
    right: 32px;
    bottom: 20px;
    height: 24px;
    width: 24px;
    background-color: $main;
    border-radius: 50%;
    border: none;
    outline: none;
    background-image: url("../../assets/icons/arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: transform 0.08s linear;
    &:hover {
      transform: scale(1.08);
    }
    &:active {
      transform: scale(1);
    }

    &--disabled {
      opacity: 0.7;

      &:hover {
        transform: none;
      }
    }
  }

  &__edit-message {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;

    p {
      margin-right: 10px;
    }

    button {
      border-radius: 50%;
      border: 2px solid #212121;
      background: transparent;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__files {
    margin-top: 16px;

    .input {
      margin-bottom: 10px;
    }

    input[type="file"] {
      position: absolute;
      top: 500px;
    }

    .file-listing {
      display: flex;
      font-size: 13px;

      span {
        margin-left: 15px;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    .names {
      margin-bottom: 16px;
    }

    span.remove-file {
      color: red;
      cursor: pointer;
      float: right;
    }

    .btns {
      button {
        border: none;
        outline: none;
        padding: 6px 15px;
        border-radius: 4px;
        color: $main;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  &__btns {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__presets-btn {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: none;
      color: $label-third;
      cursor: pointer;
      outline: none;
      font-size: 14px;
    }

    svg {
      transition: transform 0.2s linear;
      path {
        stroke: $label-third;
      }
    }

    &--rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__presets-edit {
    border: none;
    background: none;
    color: $main;
    cursor: pointer;
    outline: none;
    font-size: 14px;
  }

  &__presets {
    height: 0;
    max-height: 96px;
    overflow: auto;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    transition: height 0.2s linear;

    &--open {
      height: 96px;
    }
  }
  &__preset {
    background: #f4f5f9;
    border-radius: 8px;
    padding: 10px 50px;
    margin-right: 12px;
    margin-bottom: 6px;
    height: 32px;
    cursor: pointer;

    p {
      color: $label-main;
      font-size: 14px;
    }
  }

  .quill-editor.editor {
    background: #f5f6fa;
    border-radius: 4px;
    padding: 10px 16px;
  }
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ebecf2;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
