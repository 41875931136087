<template>
  <div class="history">
    <div class="history__list">
      <div
        class="history__item history-item"
        v-for="item in logs"
        :key="item.id"
      >
        <div class="history-item__date">
          {{ item.created_at }}
        </div>

        <div class="history-item__message">
          {{ item.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "History",
  computed: {
    ...mapState({
      logs: state => state.ticket.logs
    })
  }
};
</script>

<style lang="scss" scoped>
.history {
  &__item {
    margin-bottom: 24px;
  }

  .history-item {
    &__date {
      font-size: 14px;
      color: $label-third;
      margin-bottom: 6px;
    }

    &__message {
      font-size: 16px;
      line-height: 150%;
      color: $label-main;
    }
  }
}
</style>
