import { render, staticRenderFns } from "./TicketComments.vue?vue&type=template&id=2c405450&scoped=true&"
import script from "./TicketComments.vue?vue&type=script&lang=js&"
export * from "./TicketComments.vue?vue&type=script&lang=js&"
import style0 from "./TicketComments.vue?vue&type=style&index=0&id=2c405450&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c405450",
  null
  
)

export default component.exports