<template>
  <div class="comments">
    <div class="comments__list" id="comments-list">
      <div
        class="comments__item comments-item"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="comments-item__author">
          {{ comment.author }}
        </div>

        <div class="comments-item__message">
          <quill-editor
            class="message__text"
            :content="comment.text"
            :options="messageOption"
            :disabled="true"
          ></quill-editor>
        </div>

        <div class="comments-item__date">
          {{ comment.created_at }}
        </div>
      </div>
    </div>

    <div class="comments__editor">
      <resize-observer @notify="handleResize" />

      <div class="editor__wrapper">
        <quill-editor
          class="editor"
          v-model="content"
          :options="editorOption"
          @keydown.enter.native="handleKeypress($event)"
        ></quill-editor>

        <button
          class="editor__btn"
          :class="sending || !content ? 'editor__btn--disabled' : ''"
          @click="sendCommentMessageRequest(content)"
          :disabled="sending || !content"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Comments",
  data() {
    return {
      sending: false,
      content: "",

      editorOption: {
        theme: "bubble",
        placeholder: "Текст сообщения...",
        modules: {
          toolbar: false,
          keyboard: {
            bindings: {
              tab: false,
              handleEnter: {
                key: 13,
                handler: function() {
                  // Do nothing
                }
              }
            }
          }
        }
      },

      messageOption: {
        theme: "bubble",
        modules: {
          toolbar: false
        }
      }
    };
  },

  computed: {
    ...mapState({
      comments: state => state.ticket.comments
    })
  },

  methods: {
    ...mapActions({
      sendCommentMessage: "message/sendCommentMessage",
      loadTicketComments: "ticket/loadTicketComments"
    }),

    handleKeypress($event) {
      if (!$event.shiftKey && $event.key === "Enter" && this.content) {
        this.sendCommentMessageRequest();
      }
    },

    sendCommentMessageRequest() {
      this.sending = true;

      this.sendCommentMessage({
        id: this.$route.params.id,
        content: this.content
      })
        .then(() => {
          this.content = "";
          this.sending = false;
          this.loadTicketComments(this.$route.params.id).then(() => {
            this.scrollChat();
          });
        })
        .catch(() => {
          this.sending = false;
        });
    },

    scrollChat() {
      setTimeout(() => {
        let chat = document.getElementById("comments-list");
        chat.scrollTop = 99999;
      }, 200);
    },

    handleResize() {
      this.scrollChat();
    }
  },

  mounted() {
    this.scrollChat();
  }
};
</script>

<style lang="scss" scoped>
.comments {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__list {
    height: 100%;
    margin-bottom: 30px;
    overflow: auto;
    scroll-behavior: smooth;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .comments-item {
    &__author {
      font-size: 14px;
      color: $label-main;
      margin-bottom: 8px;
    }
    &__message {
      font-size: 14px;
      line-height: 16px;
      color: $label-second;
      margin-bottom: 8px;
    }
    &__date {
      font-size: 12px;
      line-height: 14px;
      color: $label-third;
    }
  }

  &__chat {
    padding: 0 40px 0 50px;
    overflow: auto;
    scroll-behavior: smooth;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &__editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-top: auto;
  }

  .chat {
    &__messages {
      width: 100%;

      @media (max-width: 1140px) {
        max-width: 100%;
      }

      .message {
        padding: 16px 20px 12px 16px;
        border-radius: 6px;
        margin-top: 24px;
        max-width: 70%;

        @media (max-width: 1140px) {
          max-width: 100%;
        }

        &__name {
          font-size: 14px;
          font-weight: 500;
          color: $third;
          margin-bottom: 8px;
        }
        &__text {
          .ql-editor {
            padding: 0;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 12px;
            font-family: "Rubik", sans-serif;
          }
        }
        &__date {
          font-size: 10px;
          line-height: 12px;
        }
      }
      .message--support {
        background: $main;
        .message__name {
          color: $third;
        }
        .message__text {
          .ql-editor {
            color: #f3f7ff;
          }
        }
        .message__date {
          color: #749eff;
        }
      }
      .message--user {
        background: #f4f5f9;
        .message__name {
          color: $label-main;
        }
        .message__text {
          .ql-editor {
            color: $label-second;
          }
        }
        .message__date {
          color: $label-third;
        }
      }
    }

    &__btns {
      margin-top: 24px;
      padding-bottom: 24px;

      @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      button {
        padding: 16px 0;
        width: 162px;
        border-radius: 6px;
        border: none;
        outline: none;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        cursor: pointer;
        transition: transform 0.08s linear;

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1);
        }
      }
    }

    .btns {
      &__first {
        margin-right: 40px;
        background-color: $main;
        color: $third;

        @media (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &__second {
        background-color: #f5f6fa;
        color: $main;
      }
    }
  }

  .editor__wrapper {
    position: relative;
    .editor__btn {
      position: absolute;
      right: 32px;
      bottom: 20px;
      height: 24px;
      width: 24px;
      background-color: $main;
      border-radius: 50%;
      border: none;
      outline: none;
      background-image: url("../../assets/icons/arrow-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: transform 0.08s linear;
      &:hover {
        transform: scale(1.08);
      }
      &:active {
        transform: scale(1);
      }

      &--disabled {
        opacity: 0.7;

        &:hover {
          transform: none;
        }
      }
    }
  }

  .quill-editor.editor {
    background: white;
    border-radius: 4px;
    padding: 10px 16px;
  }
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ebecf2;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
