<template>
  <div class="user-info">
    <div class="user-info__user user">
      <p class="user__name">{{ userInfo.client.name }}</p>
      <!--      <p class="user__email">{{ userInfo.email }}</p>-->
      <p class="user__phone">{{ userInfo.client.phone }}</p>
      <div class="user__additional-data">
        <div v-if="userInfo.client.client_source">
          <p><b>Клиентский сервис:</b></p>
          <p v-if="userInfo.client.client_source.email">
            {{ userInfo.client.client_source.email }}
          </p>
          <p v-if="userInfo.client.client_source.last_name">
            {{ userInfo.client.client_source.last_name }}
          </p>
          <p v-if="userInfo.client.client_source.first_name">
            {{ userInfo.client.client_source.first_name }}
          </p>
          <p v-if="userInfo.client.client_source.phone">
            {{ userInfo.client.client_source.phone }}
          </p>
        </div>
        <div v-if="userInfo.client.distrib_source">
          <p><b>Кабинет дистрибьютора:</b></p>
          <p v-if="userInfo.client.distrib_source.last_name">
            {{ userInfo.client.distrib_source.last_name }}
          </p>
          <p v-if="userInfo.client.distrib_source.first_name">
            {{ userInfo.client.distrib_source.first_name }}
          </p>
        </div>
      </div>
    </div>

    <div class="user-info__history">
      <h4>История тикетов</h4>
      <div class="history">
        <div
          class="history__item"
          v-for="item in userInfo.themes"
          :key="item.id"
          @click="selectTheme(userInfo.client.phone, item.id)"
        >
          <p>
            {{ getThemeById(item.id).name }}<span>{{ item.count }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { eventBus } from "@/main";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: state => state.ticket.userInfo
    }),
    ...mapGetters({
      getThemeById: "theme/getThemeById"
    })
  },
  methods: {
    selectTheme(phone, theme) {
      eventBus.$emit("selectUserTheme", {
        phone: phone,
        theme: theme
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-info {
  &__user {
    margin-bottom: 40px;
  }

  .user {
    &__name {
      font-size: 16px;
      color: $label-main;
      margin-bottom: 16px;
    }
    &__email {
      margin-bottom: 12px;
    }
    &__email,
    &__phone {
      font-size: 14px;
      color: $label-third;
      margin-bottom: 14px;
    }

    &__additional-data {
      & p {
        margin-bottom: 4px;
      }
    }
  }

  &__history {
    h4 {
      margin-bottom: 35px;
      font-size: 18px;
      color: $label-main;
    }
  }

  .history {
    &__item {
      background: #ffffff;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
      border-radius: 4px;
      padding: 16px 30px;
      margin-bottom: 12px;
      cursor: pointer;
      transition: transform 0.06s linear;
      &:hover {
        transform: scale(1.03);
      }
      &:active {
        transform: scale(1);
      }
      p {
        font-size: 14px;
        line-height: 17px;
        color: $label-main;
        display: flex;
        align-items: center;
      }
      span {
        background: rgba(189, 189, 189, 0.2);
        border-radius: 4px;
        color: $label-third;
        padding: 2px 5px;
        margin-left: 12px;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}
</style>
